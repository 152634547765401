
body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.border-solid {
    border-style: solid;
}


.landing-gradient {
    background-color:hsla(266,87%,79%,1);
    background-image:
            radial-gradient(at 7% 40%, hsl(249, 78%, 71%) 0px, transparent 50%),
            radial-gradient(at 2% 7%, hsl(252, 100%, 73%) 0px, transparent 50%),
            radial-gradient(at 15% 96%, hsla(202,97%,74%,1) 0px, transparent 50%),
            radial-gradient(at 27% 62%, hsl(244, 76%, 73%) 0px, transparent 50%),
            radial-gradient(at 80% 74%, hsl(344, 88%, 68%) 0px, transparent 50%),
            radial-gradient(at 93% 79%, hsla(13,100%,72%,1) 0px, transparent 50%);
    background-size: 125% 125%;


}

.landing-gradient-animated {
    /*-webkit-animation: gradientAnimation 15s ease infinite;*/
    /*-moz-animation: gradientAnimation 15s ease infinite;*/
    /*animation: gradientAnimation 15s ease infinite;*/
    /*animation-direction: alternate;*/
}

@-webkit-keyframes gradientAnimation {
    0% {
        background-position: 0 10%
    }

    25% {
        background-position: 25% 75%
    }
    50% {
        background-position: 100% 50%
    }
    75% {
        background-position: 25% 75%
    }
    100% {
        background-position: 0 50%
    }
}

@-moz-keyframes gradientAnimation {
    0% {
        background-position: 0 10%
    }

    25% {
        background-position: 25% 75%
    }
    50% {
        background-position: 100% 50%
    }
    75% {
        background-position: 25% 35%
    }
    100% {
        background-position: 0 50%
    }
}

@keyframes gradientAnimation {
    0% {
        background-position: 0 15%
    }

    25% {
        background-position: 25% 75%
    }
    50% {
        background-position: 100% 50%
    }
    75% {
        background-position: 25% 35%
    }
    100% {
        background-position: 0 25%
    }
}

.list-group {
    overflow: hidden;
}

.carousel-inner {
    height: 100%;
}

.carousel-indicators {
    margin-bottom: 0 !important;
}

.carousel-indicators button {
    width: 6px !important;
    height: 6px !important;
    border-radius: 50%;
    transition: none !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}



/*.form-control:focus {*/
/*    border-width: 1px;*/
/*    border-style: solid;*/
/*    border-bottom-color: var(--bs-primary) !important;*/
/*    outline: none !important;*/
/*    box-shadow: none !important;*/
/*}*/


.nav-panel {
    -webkit-box-shadow: 0 4px 31px 40px rgba(0,0,0,1);
    -moz-box-shadow: 0 4px 31px 40px rgba(0,0,0,1);
    box-shadow: 0 4px 31px 40px rgba(0,0,0,1);
}

.bg-opacity-95 {
    --bs-bg-opacity:0.95 !important;
}



.display-xl {
    font-size: calc(2.625rem + 8vw);
    font-weight: 300;
    line-height: 1.2;
}
@media (min-width: 1200px) {
    .display-xl {
        font-size: 10rem;
    }
}

.text-gradient-primary {
    background: linear-gradient(120deg, var(--bs-primary), #E866FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

:root {
    --animation-duration: 0.25s;
}



.enter-up {
    animation-duration: var(--animation-duration);
    animation-name: enter-up-animation;
    animation-fill-mode: forwards;
}

.enter-up-start {
    /*transform: translateY(300px);*/

    /*padding-top: 200px;*/
    opacity: 0;
}

@keyframes enter-up-animation {
    from {
        transform: translateY(300px);
        /*padding-top: 200px;*/
    }

    to {
        transform: translateY(0);
        /*padding-top: 0;*/
        opacity: 100%;
    }
}

.enter-left {
    animation-duration: var(--animation-duration);
    animation-name: enter-left-animation;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

.enter-left-start {
    opacity: 0;
}

@keyframes enter-left-animation {
    from {
        transform: translateX(300px);
    }

    to {
        transform: translateX(0);
        opacity: 100%;
    }
}

.enter-scale {
    animation-duration: var(--animation-duration);
    animation-name: enter-scale-animation;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

@keyframes enter-scale-animation {
    from {
        width: 0;
    }

    to {
        width: 100%;

    }
}
