ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: disc;
}

li strong:after {
    content: " "
}

ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 2.5em;
}

ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

li ol > li {
    margin: 0;
}

li ol > li:before {
    content: counters(item, ".") ". ";
}
