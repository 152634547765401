
$primary: rgb(116, 84, 194);
$secondary: rgb( 169, 135, 255);
$dark: rgb(20, 22, 24);
//$dark: rgb(11, 23, 36);
$dark-highlight: rgb(46,48,52);

$menu: rgb(27, 29, 32);


$border-color: rgb(55,55,55);

$text-muted: rgba(255,255,255,0.5);

$accordion-bg: $menu;
$accordion-color: #fff;
$accordion-button-active-color: $dark;

$card-bg: rgb(46,48,52);
$card-border-color: $card-bg;

$modal-content-bg: $dark;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-content-color: "#fff";

$form-range-track-height: 2px;
$form-range-thumb-width: 20px;
$form-range-thumb-height: 20px;


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&family=Rubik:wght@300;400;500;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

$font-family-sans-serif: 'Inter', sans-serif;
$headings-font-family: 'Rubik', sans-serif;
$headings-font-weight: 400;

$btn-border-radius: 0.5rem;
$border-radius: 0.75rem;
$border-radius-lg: 1rem;
$list-group-border-radius: 1rem;

$toast-border-radius: 0.5rem;

$modal-content-border-radius: .5rem;

$nav-pills-link-active-bg: rgb(116, 84, 194, 0);
$nav-link-color: #bebebe;

$gray-50: #FBFBFC;
//$gradient: linear-gradient(180deg, rgba(#fff, .3), rgba(#fff, 0));
//$light: $gray-50;
$nav-link-hover-color: #fff;

.bg-dark-highlight {
  --bs-bg-opacity:1;
  background-color:rgba(46,48,52,var(--bs-bg-opacity))!important;
}

@import "~bootstrap/scss/bootstrap";

.list-group-flush {
border-radius: 1rem;
}

.bg-menu {
  background-color: $menu;
}

.landing-jumbo-margin {
  margin-top: 50px;
  margin-bottom: 300px;
}

@include media-breakpoint-up(md) {
  .landing-jumbo-margin {
    margin-top: 150px;
    margin-bottom: 400px;
  }
}
