.toggle-switch-container {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.toggle-switch-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0,0,0,0.5);
    transition: 0.3s;
    border-radius: 30px;
}

.toggle-switch:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 3px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

.toggle-switch-input:checked + .toggle-switch {
    background-color: #7454c2;
}

.toggle-switch-input:checked + .toggle-switch:before {
    transform: translateX(19px);
}
